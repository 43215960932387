// import PropTypes from 'prop-types'
import React, {
  useEffect,
  useState
  // useEffect 
} from "react"
import { useDispatch } from "react-redux"

import {
  Row, Col, Input, Label, Button, UncontrolledAlert, Container
} from "reactstrap"

import MetaTags from 'react-meta-tags';

// actions
// import {
//   changeCrumb
// } from "../../store/actions"

// import { MetaTags } from 'react-meta-tags'

import img_contact_us from "assets/images/contact-us.png"
import { isValidEmail } from "helpers/utils";
import { sendContactMsg } from "store/actions";

const ContactUsPage = props => {
  const { t } = props
  const dispatch = useDispatch();

  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [msg_check, setMsgCheck] = useState("")
  const [msg_success, setMsgSuccess] = useState("")
  const [loading_submit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    setMsgCheck("")
    setMsgSuccess("")
  }, [])

  const checkData = () => {
    if (!company || company === "") return "Entreprise est obligatoire.";
    if (!email || email === "" || !isValidEmail(email)) return "Email est obligatoire.";
    if (!message || message === "") return "Entreprise est obligatoire.";

    return ""
  }

  const submit = () => {
    setMsgSuccess("");
    const check = checkData(); setMsgCheck(check)

    if (check === "") {
      setLoadingSubmit(true)
      dispatch(sendContactMsg({ data: { company, email, message } }, (resp) => {
        // console.log("=============++++> ", resp);
        setLoadingSubmit(false)
        setMsgSuccess("Votre message a été envoyé avec succès. Nous répondrons dès que possible");
        setCompany("");
        setEmail("");
        setMessage("");
        // history.push('/staffs/clockingin');
        window.scrollTo(0, 0)
      }, (error) => {
        setLoadingSubmit(false)
        window.scrollTo(0, 0)
        setMsgCheck(error?.message || t('checkData'))
      }))
    }

    else window.scrollTo(0, 0)
  }
  return (
    <React.Fragment>
      <MetaTags><title>AppliBTP</title></MetaTags>

      <div className="question-section">
        <div className="container">
          <div className="question" style={{ width: 280, margin: "auto" }}>
            <h1 className="title">
              <span>Contactez-Nous</span>
              <span className="sm-underline" style={{ width: 85, marginTop: 5 }} />
            </h1>
          </div>
        </div>
      </div>

      <Container>
        <div style={{ padding: "0 35px" }}>
          <Row style={{ width: "100%" }}>
            <Col md="6">
              <div className="explication-section pt-5 pb-5">
                <div className="container">
                  <h4 className="title">Vous souhaitez avoir plus <br />d'<span className="hilighted">informations</span>!</h4>
                  <p className="grey">Nous sommes là pour vous assister 24/7j</p>
                </div>
              </div>

              <div style={{ width: "100%", textAlign: "center" }}>
                <img className="mt-4" src={img_contact_us} alt="#" style={{ width: "80%", margin: "auto" }} />
              </div>
            </Col>
            <Col md="6">
              <div className="mt-5">
                <Label style={{ fontSize: 17, marginBottom: 30 }}>Formulaire de contact</Label>

                {msg_check !== "" && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
                  <i className="mdi mdi-block-helper me-2"></i>{msg_check}
                </UncontrolledAlert>}

                {msg_success !== "" && <UncontrolledAlert color="success" className="hide-alert-close" role="alert" >
                  <i className="mdi mdi-block-helper me-2"></i>{msg_success}
                </UncontrolledAlert>}

                <Input value={company} onChange={(e) => setCompany(e.target.value)} className="mb-3" placeholder="Entreprise" />
                <Input value={email} onChange={(e) => setEmail(e.target.value)} className="mb-3" placeholder="Adresse e-mail" />
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" rows="8" placeholder="Message" />

                <div style={{ textAlign: "center" }}>
                  <Button onClick={() => {
                    if (!loading_submit) submit()
                  }} color="primary" className="mt-3" style={{ width: 180, borderRadius: 50 }} >Envoyer</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  )
}

ContactUsPage.propTypes = {
}
export default ContactUsPage