import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-dark.png"
import { withTranslation } from 'react-i18next';

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const { user } = useSelector(({ Login }) => Login);

  const renderAler = () => {
    if (props.error?.response?.data?.error?.message) return <Alert color="danger">{props.error?.response?.data?.error?.message}</Alert>
    else return <Alert color="danger">{props.t('checkData')}</Alert>
  }
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (user?._id && localStorage.getItem("authUser") !== null) {
      window.location.href = "/dashboard"
    }
  }, [user])

  return (
    <React.Fragment>
      <MetaTags><title>{props.t('loginButton')} | AppliBTP</title></MetaTags> 
      <div className="account-pages pt-sm-5" style={{ backgroundColor: "#f8f8fb", minHeight: 900 }} >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="" style={{ backgroundColor: "#264b9a", height: 130 }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary" style={{ padding: "19px 0px 19px 19px" }}>
                        <h5 className="" style={{ color: "#fff", fontSize: 21 }} >{props.t('welcomeMessage')}</h5>
                        <p style={{ color: "#fff", fontSize: 12 }}>{props.t('continueToApp')}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title login-logo-wrap">
                          <img
                            src={logo}
                            alt=""
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      {props.error && props.error !== "" ? renderAler() : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={props.t('emailAddress')}
                          value=""
                          className="form-control"
                          placeholder="Email"
                          type="text"
                          validate={{
                            email: {value : true , errorMessage :props.t('invalidEmail')},
                            required: { value: true, errorMessage: props.t('requiredEmail') },
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={props.t('password')}
                          value=""
                          type={showPassword ? 'text' : 'password'}
                          placeholder={props.t('password')}
                          validate={{
                            required: { value: true, errorMessage: props.t('requiredPassword') },
                            minLength: { value: 7, errorMessage: props.t('passwordMinLength') },
                            maxLength: { value: 16, errorMessage: props.t('passwordMaxLength') } 
                          }}
                        />
                        <div onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}
                          style={{ marginTop: '5px', color: '#1e3c7b', cursor: 'pointer' }}>
                          <span>{props.t('viewPassword')}</span>
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {props.t('login')}
                        </button>
                      </div>
                      <Col md="12">
                        <Link to="/forgot-password" style={{ textDecoration: "underline !important", textAlign: "center", display: "block", marginTop: 10 }}>{props.t('forgotPassword')}</Link>
                      </Col>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <Link to="/mentions-legales" style={{ textTransform: "uppercase", textAlign: "center", display: "block", marginTop: 10 }}>{props.t('legalNotice')}</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(withTranslation()(Login))
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}
