/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody } from "reactstrap"

// Redux
import { useDispatch } from "react-redux"

// actions
import {
  changeCrumb,
  getListInscriptions, //checkUserSubscription
  editInscription,
  deleteInscription,
} from "../../store/actions"
import SearchInput from "components/Common/Tools/SearchInput/Search"
import DropStatuses from "components/Common/DropStatuses/DropStatuses"
import Limitation from "components/Common/Tools/Limitation/Limitation"
import Pagination from "components/Common/Pagination/Pagination"
import { getCountPages } from "helpers/utils"
import ListingTable from "components/Common/ListingTable/ListingTable"
import ListingTableTD from "components/Common/ListingTable/ListingTableTD"
import moment from "moment"
import Select from "react-select"
import AddBankTransfertMdl from "components/Modals/Subscriptions/AddBankTransfertMdl"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"



const UserSubscriptionsPage = props => {
  const { t } = props
  const statuses = [
    {
      label: t('created_'),
      value: 0,
      color: "#ff0000", //"#264b9a",
    },
    {
      label: t('validated'),
      value: 1,
      color: "#15a920",
    },
    {
      label: t('expired'),
      value: 2,
      color: "#79849e",
    },
    {
      label: t('canceled'),
      value: 3,
      color: "#ff8800",
    },
    {
      label: t('renewed'),
      value: 4,
      color: "#50a5f1",
    },
    {
      label: t('paid'),
      value: 5,
      color: "#15a920",
    },
  
  ]
  const dispatch = useDispatch()

   
  const [open_limits, setOpenLimits] = useState(false)
  const [limit, setLimit] = useState(20)
  const [current_page, setCurrentPage] = useState(1)

  const [count_all, setCountAll] = useState(0)
  const [data, setData] = useState([])

  const [sort_by, setSortBy] = useState({
    name: t('dateDescending'),
    value: "-_id",
  })
  const [query_name, setQueryName] = useState("")
  const [status, setStatus] = useState(-1)
  const [open_add_bank_transfert_mdl, setOpenAddBankTransfertMdl] = useState(null)

  const [selected_sub, setSelectedSub] = useState(null)
  const [show_alert_delete, setShowAlertDelete] = useState(false)
  const [selected_inscription, setSelectedInscription] = useState(null)
  // const [selectedStatus, setSelectedStatus] = useState("0")
  const [success_dlg, setSuccessDLG] = useState(false)

  const styleData = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isFocused ? "bold" : "normal",
      // backgroundColor: "white",
      color: state.data.color,
      icon: state.data.icon,
      minHeight: '40px',
      height: '40px',
    }),
    
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      icon: state.data.icon,
      marginBottom : 5
    }),
    menu: provided => ({ ...provided, zIndex: 999 })
  }

  async function editStatus(_id, status) {
    if(status === 5){setOpenAddBankTransfertMdl(true);setSelectedSub(_id)}

    dispatch(
      editInscription(
        { _id, data: { status } },
        res => {
          // console.log("succes", { res })
          getPage()
        },
        e => {
          console.log("failed", { e })
        }
      )
    )
  }

  useEffect(() => {
    dispatch(
      changeCrumb(t('subscriptions')),
      resp => { },
      error => { }
    )

    getPage()
  }, [limit, sort_by , query_name , status])

  const getPage = (page = 1) => {
    const offset = page === 1 || page === 0 ? 0 : limit * (page - 1)
    const filters = {
      clientName: query_name,
      sort: sort_by.value,
    }
    if (status !== -1 && status !== "all") filters["status"] = status
    dispatch(
      getListInscriptions(
        {
          offset,
          limit,
          filters
        },
        resp => {
          console.log(resp?.inscriptions)
          setData(resp?.inscriptions || [])
          setCountAll(resp?.counts || 0)
        },
        err => { }
      )
    )
  }

 
  const deleteSelectedCLient = () => {
    if (selected_inscription) {
      dispatch(deleteInscription({ _id: selected_inscription?._id }, (resp) => {
        setSuccessDLG(true)
        getPage(current_page)
        setSelectedInscription(null)
      }, (error) => {
        getPage(current_page)
        setSelectedInscription(null)
      }))
    }
  }
  const headers = [
    { name: t('client') },
    { name: t('start'), sorting: true, sort_by: "startAt" },
    // { name: "Fin", sorting: true, sort_by: "expiredAt" },
    { name: t('Modules') },
    { name: t('storage') },
    { name: t('additionalStorages') },

    { name: t('totalCurrency'), sorting: true, sort_by: "total" },
    { name: t('ttcCurrency'), sorting: true, sort_by: "totalTTC" },

    { name: t('Statut'), style: { width: 120 } ,detailsSubscription:true},
    { name: "" },

  ]


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('subscriptions')}</title>
        </MetaTags>
        <Container fluid>
          <Row className="mb-3">
            <Col md="6" style={{display:'flex'}}>
              <div style={{ maxWidth: 225 }}>
                <SearchInput
                  onChange={e => setQueryName(e.target.value)}
                  // hide_icon
                  icon_name="mdi mdi-account-search-outline"
                  onIconClick={() => getPage()}
                />
              </div>
              <div style={{marginLeft:10}}>
              <DropStatuses
              
            statuses={[
              {
                name: t('created_'),
                value: 0,
              },
              {
                name: t('validated'),
                value: 1,
              },
              {
                name: t('expired'),
                value: 2,
              },
              {
                name: t('canceled'),
                value: 3,
              },
              {
                name: t('renewed'),
                value: 4,
              },
              {
                name: t('paid'),
                value: 5,
              },
              {
                name: t('allSubscriptions'),
                value: "all",
                color: "#264B9A",
              },
            ]}
            onStatusChanged={new_status => {
              if (new_status) setStatus(new_status.value)
            }}
            default_status={{
              name: t('allSubscriptions'),
              value: "all",
              color: "#264B9A",
            }}
          />
              </div>
              
            </Col>

            <Col md="6">
              <div className="float-right">
                <Limitation
                  options={[5, 10, 20]}
                  text={t('perPage')}
                  limit={limit}
                  setLimit={setLimit}
                  setOpenLimits={setOpenLimits}
                  open_limits={open_limits}
                />
              </div>
            </Col>
          </Row>

          <Card className="" style={{marginBottom:'8rem'}}>
            <CardBody className="p-0 mb-5" style={{ minHeight: 100 }}>
              <ListingTable container_style={{ overflow: "visible" }} table_style={{ overflow: "visible" }}
                headers={headers}
                changeSortBy={(new_sort_by = "") => setSortBy(new_sort_by)}
              >
                {data &&
                  data?.map((item, i) => (
                    <tr key={"subscribtion" + i}>
                      <ListingTableTD
                        data={{
                          name: item?.company?.name ? item?.company?.name :  (item.user?.profile?.fullName && item.user?.profile?.fullName !== "") ? item.user?.profile?.fullName : (item.user?.profile?.firstName || "") + " " + (item.user?.profile?.lastName || ""),
                          class: "",
                          style: {},
                          type: "string",
                        }}
                      />

                      <ListingTableTD
                        data={{
                          name: moment(item?.beginAt).format("DD-MM-YYYY"),
                          class: "",
                          style: {},
                          type: "string",
                        }}
                      />

                      {/* <ListingTableTD
                        data={{
                          name: moment(item?.expiredAt).format("DD-MM-YYYY"),
                          class: "",
                          style: {},
                          type: "string",
                        }}
                      /> */}

                      <td style={{display:'inline-grid'}}>
                        {item.modules?.map((mdl, indx) => {
                          if (!mdl || !mdl.countUsers) return null
                          return (
                            <span
                              key={`mdl-${indx}`}
                              style={{
                                backgroundColor: "#50a5f1",
                                borderRadius: 5,
                                padding: 5,
                                color: "#fff",
                                margin: 2,
                              }}
                            >
                              {mdl.name}{" "}
                              <i className="mdi mdi-account-supervisor" /> *{" "}
                              {mdl.countUsers || 0}
                            </span>
                          )
                        })}
                      </td>

                    
                        <td>{Number(item.storage || 0) } (Go)</td>
                        <td>{Number(item.storageAdditions?.additionSize || 0) } (Go)</td>


                      <ListingTableTD
                        data={{
                          name: (item?.total).toFixed(2),
                          type: "number",
                        }}
                      />

                      <ListingTableTD
                        data={{
                          name: (item?.totalTTC).toFixed(2),
                          type: "number",
                          class: "hilighted",
                        }}
                      />

                      <td>
                        {/* {renderStatus(item.status?.code)} */}

                        <div className="">
                        <Select
                        classNamePrefix="select"
                          value={statuses.filter(
                            option =>
                              option.value === item?.status?.code
                          )}
                          styles={styleData}
                          onChange={e => {
                            editStatus(item?._id, e?.value)
                          }}
                          options={statuses}
                        />
                        </div>
                      </td>


                      <td className="actions">
                        <Link to={{ pathname: `/user-subscriptions/preview/${item._id}`, state: item }}>
                          <i className="mdi mdi-eye"></i>
                        </Link>
                        {['0', '1', '3'].includes(String(item?.status?.code)) ? <i className="mdi mdi-trash-can-outline"
                          onClick={(e) => {
                            setShowAlertDelete(true)
                            setSelectedInscription(item)
                          }}>
                        </i>
                          : null}
                      </td>
                      <AddBankTransfertMdl subscription={selected_sub}
                        is_open={open_add_bank_transfert_mdl} toggleOpen={() => setOpenAddBankTransfertMdl(!open_add_bank_transfert_mdl)}
                        accept={() => { getPage(); setOpenAddBankTransfertMdl(false) }}
                      />

                      {/* <td></td> */}
                    </tr>
                  ))}
              </ListingTable>
             
              <Pagination
                currentPage={current_page}
                totalPage={getCountPages(count_all, limit)}
                onChangePage={i => {
                  setCurrentPage(i)
                  getPage(i)
                }}
              />
                    {show_alert_delete && <SweetAlert
        title={t('areYouSure')}
        warning
        showCancel
        confirmButtonText={t('confirmDelete')}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setShowAlertDelete(false)
          deleteSelectedCLient(true)
          // setdynamic_title("Deleted")
          // setdynamic_description("Your file has been deleted.")
        }}
        onCancel={() => setShowAlertDelete(false)}
      >
        {t('cannotUndoWarning')}
      </SweetAlert>}


      {success_dlg ? (
        <SweetAlert 
          success
          title={t('deleted')}
          onConfirm={() => {
            setSuccessDLG(false)
          }}
        >
          {t('registrationDeleted')+".."}
        </SweetAlert>
      ) : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
UserSubscriptionsPage.propTypes = {
  changeCrumb: PropTypes.func,
}

export default withTranslation() (UserSubscriptionsPage)
