// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs"
import axiosApi from "../helpers/axiosConfig"

const InscriptionSrv = {
  // =============== GET LIST
  getList: payload => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (/*payload.filters[key] &&*/ payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi
        .get(
          `${endpoints.inscriptions}/${payload?.offset || 0}/${
            payload?.limit || 10
          }/?${filters}`
        )
        .then(resp => {
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },

  // =============== GET ONE
  getOne: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .get(`${endpoints.inscriptions}/${payload?._id}`)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },

  // =============== CREATE
  create: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .post(endpoints.inscriptions, payload)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH
  patch: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .patch(`${endpoints.inscriptions}/${payload?._id}`, payload.data)
        .then(resp => {
          console.log({ resp })
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },

  // =============== DELETE
  delete: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .delete(`${endpoints.inscriptions}/${payload?._id}`)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },
}

export default InscriptionSrv
