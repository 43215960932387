import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

// import { NavLink } from "react-router-dom";

import { Card, CardBody, Row, Col } from "reactstrap"

// Redux
import { useDispatch } from "react-redux"
// import { withRouter } from "react-router-dom"

import SweetAlert from "react-bootstrap-sweetalert"
//ACTIONS
import { editSubscriber, getListSubscribers } from "store/actions"
// COMPONENTS
// import orange from "../../assets/images/orange.png";

import Limitation from "components/Common/Tools/Limitation/Limitation"
// import AddBtn from 'components/Common/Tools/AddBtn/AddBtn';
// import SortBy from 'components/Common/Tools/SortBy/SortBy';
import ListingTable from "components/Common/ListingTable/ListingTable"
import ListingTableTD from "components/Common/ListingTable/ListingTableTD"
import SearchInput from "components/Common/Tools/SearchInput/Search"
import { isFunction, getCountPages } from "helpers/utils"
import Pagination from "components/Common/Pagination/Pagination"
// import { endpoints } from 'helpers/apiConfigs';

import Switch from "rc-switch"
import "rc-switch/assets/index.css"
import { withTranslation } from "react-i18next"

const ListSubscribersCmp = props => {
  // 
  const { show_selection, selection_multi, default_selected_clients,t } = props

  const dispatch = useDispatch()
  const [subscribers, setSubscribers] = useState([])
  const [count_all_clients, setCountAll] = useState(0)

  const [selected_clients, setSelectedClients] = useState([])

  // const [open_sorts, setOpenSorts] = useState(false)
  const [sort_by, setSortBy] = useState({
    name: t('dateDescending'),
    value: "-_id",
  })
  const [query_name, setQueryName] = useState("")

  const [open_limits, setOpenLimits] = useState(false)
  const [limit, setLimit] = useState(20)
  const [current_page, setCurrentPage] = useState(1)

  const [show_alert_delete, setShowAlertDelete] = useState(false)
  const [show_alert_activation, setShowAlertActivation] = useState(false)

  const [selected_user, setSelectedUser] = useState(null)
  const [loading_active, setLoadingActive] = useState(false)


  useEffect(() => {
    getPage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query_name, limit, sort_by])

  const getPage = (page = 1) => {
    const offset = page === 1 || page === 0 ? 0 : limit * (page - 1)
    // console.log({ offset, limit, sort_by });
    dispatch(
      getListSubscribers(
        {
          offset,
          limit,
          filters: {
            fullName: query_name,
            sort: sort_by.value,
          },
        },
        resp => {
          console.log({ resp })
          setSubscribers(resp?.subscribers || [])
          setCountAll(resp?.counts || 0)
        },
        err => {}
      )
    )
  }

  useEffect(() => {
    if (isFunction(props.changeSelection))
      props.changeSelection(selected_clients)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_clients])

  useEffect(() => {
    setSelectedClients(
      default_selected_clients ? [...default_selected_clients] : []
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_selected_clients])

  const deleteSelectedCLient = () => {
    // if (selected_client) {
    //   dispatch(deleteClient({ _id: selected_client._id }, (resp) => {
    //     // setSuccessDLG(true)
    //     getPage(current_page)
    //   }, (error) => {
    //     getPage(current_page)
    //     // alert(error.message || t('checkData'))
    //   }))
    // }
  }

  const isDefaultChecked = client_id => {
    if (Array.isArray(default_selected_clients))
      for (const cl of default_selected_clients) {
        if (cl?.name === client_id) return true
      }

    return false
  }

  const changeSelectionList = client => {
    if (!props.selection_multi) setSelectedClients([client])
    else {
      let exist_index = -1
      for (let i = 0; i < selected_clients.length; i++) {
        if (selected_clients[i].name === client.name) {
          exist_index = i
          break
        }
      }

      const new_selected_clients = selected_clients

      if (exist_index > -1) new_selected_clients.splice(exist_index, 1)
      else new_selected_clients.push(client)

      setSelectedClients([...new_selected_clients])
    }
  }

  const activeUser = active => {
    setLoadingActive(true)
    dispatch(
      editSubscriber(
        {
          _id: selected_user?._id,
          data: {
            isActive: !selected_user.isActive,
          },
        },
        resp => {
          getPage()
          setLoadingActive(false)
        },
        err => {
          getPage()
          setLoadingActive(false)
        }
      )
    )
  }

  const headers = [
    { name: t('client'), sorting: true, sort_by: "firstName" },
    { name: t('e-mail'), sorting: true, sort_by: "email" },
    { name: t('activation'), style: { width: 120 } }
  ];
  
  if (show_selection) headers.unshift({ name: "" })

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col md="6">
          <div style={{ maxWidth: 225 }}>
            <SearchInput
              onChange={e => setQueryName(e.target.value)}
              // hide_icon
              icon_name="mdi mdi-account-search-outline"
              onIconClick={() => getPage()}
            />
          </div>
        </Col>

        <Col md="6">
          <div className="float-right">
            <Limitation
              options={[5, 10, 20]}
              text={t('perPage')}
              limit={limit}
              setLimit={setLimit}
              setOpenLimits={setOpenLimits}
              open_limits={open_limits}
            />
          </div>

          {/* <div className="float-right mr-2 ml-2">
            <SortBy
              sort_by={sort_by.name} setSortBy={setSortBy}
              open_sorts={open_sorts} setOpenSorts={setOpenSorts}
              options={[{
                name: t('name'), value: "firstName"
              }, {
                name: t('nameDescending'), value: "-firstName"
              }, {
                name: t('date'), value: "_id"
              }, {
                name: t('dateDescending'), value: "-_id"
              },]}
            />
          </div> */}
          {/* <div className="float-right" style={{ marginRight: 10, marginLeft: 10, marginTop: 3 }}>
            <NavLink href={"/clients/add"} to={"/clients/add"}>
              <AddBtn text={t('addAction')} />
            </NavLink>
          </div> */}
        </Col>
      </Row>

      <Card>
        <CardBody className="p-0" style={{ minHeight: 100 }}>
          <ListingTable
            headers={headers}
            changeSortBy={(new_sort_by = "") => setSortBy(new_sort_by)}
          >
            {subscribers &&
              subscribers?.map((item, i) => (
                <tr key={"subscriber_" + i}>
                  {/* CHECK */}
                  {show_selection && (
                    <ListingTableTD
                      data={{
                        name: "#",
                        type: selection_multi ? "checkbox" : "radio",
                        value: item.name,
                        defaultChecked: isDefaultChecked(item.name),
                        onChange: val => {
                          changeSelectionList(item)
                        },
                      }}
                    />
                  )}

                  {/* IMAGE */}

                  {/* <ListingTableTD data={{
                name: "#", class: "", style: {}, type: "image",
                value: item.profile?.picture ? `${endpoints.root}/${item.profile?.picture?.path}` : null,
                inner_style: { borderRadius: 5 }, inner_class: "element-logo",
              }} /> */}

                  {/* NAME */}
                  <ListingTableTD
                    data={{
                      name: item?.company?.name ? item?.company?.name :  (item.profile?.fullName && item?.profile?.fullName !=="") ? item?.profile?.fullName :  (item?.profile?.firstName || "")+" "+(item?.profile?.lastName || ""),
                      class: "",
                      style: {},
                      type: "link",
                      value: "/subscribers/preview/" + item._id,
                    }}
                  />

                  {/* EMAIL */}
                  <ListingTableTD
                    data={{
                      name: item.email,
                      type: "string",
                    }}
                  />

                  {/* Department */}
                  {/* <ListingTableTD
                    data={{
                      name: item.profile?.address
                        ? item.profile?.address.department
                        : "",
                      type: "string",
                    }}
                  /> */}

                  {/* REGION */}
                  {/* <ListingTableTD
                    data={{
                      name: item.profile?.address
                        ? item.profile?.address.region
                        : "",
                      type: "string",
                    }}
                  /> */}

                  {/* ACTIONS */}
                  {/* <ListingTableTD data={{
                name: "", class: "actions", type: "actions",
                value: [
                //   {
                //   type: "link", to: `/clients/preview/${item._id}`, icon: "mdi mdi-eye"
                // }, {
                //   type: "link", to: `/clients/edit/${item._id}`, icon: "mdi mdi-pencil-box-outline"
                // },
                  // {
                  //   type: "button", onClick: () => {
                  //     setShowAlertDelete(true)
                  //     setSelectedClient(item)
                  //   }, icon: "mdi mdi-trash-can-outline"
                  // }
                ]
              }} /> */}

                  <td>
                    <Switch
                      className="custom-switch custom-switch-primary"
                      // style={{ backgroundColor: item.isActive ? "#4caf50" : '#8E104B' }}
                      checked={item.isActive ? true : false}
                      // checkedChildren="Actif" unCheckedChildren="Inactif"
                      onChange={activation => {
                        if (!loading_active) {
                          setSelectedUser(item)
                          setShowAlertActivation(true)
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
          </ListingTable>

          <Pagination
            currentPage={current_page}
            totalPage={getCountPages(count_all_clients, limit)}
            onChangePage={i => {
              setCurrentPage(i)
              getPage(i)
            }}
          />
        </CardBody>
      </Card>

      {show_alert_delete && (
        <SweetAlert
        style={{ height: "auto" }}
          title={t('areYouSure')}
          warning
          showCancel
          confirmButtonText={t('confirmDelete')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setShowAlertDelete(false)
            deleteSelectedCLient(true)
            // setdynamic_title("Deleted")
            // setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setShowAlertDelete(false)}
        >
          {t('cannotUndoWarning')}
        </SweetAlert>
      )}

      {show_alert_activation && selected_user && (
        <SweetAlert
        style={{ height: "auto" }}
          title={t('areYouSure')}
          warning
          showCancel
          // confirmButtonText={t('areYouSure')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setShowAlertActivation(false)
            activeUser()
          }}
          onCancel={() => {
            setShowAlertActivation(false)
            setSelectedUser(null)
          }}
        >
          {t('changeActivation')}
        </SweetAlert>
      )}
    </React.Fragment>
  )
}
ListSubscribersCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_clients: PropTypes.array,
}

export default withTranslation() (ListSubscribersCmp)
