import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { getPermissions } from "store/actions"
import { getLicences } from "store/permissions/actions"

const Sidebar = props => {
  const dispatch = useDispatch();
  let { user, permissions, t } = props
  const [menu_data, setMenuData] = useState([{
    path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: t('dashboard'),
    models: ['ALL'],
    active: false
  }])
  // const [licences, setLicences] = useState([])
  const [loading, setLoading] = useState(false)

  



  const all_menu_data = [{
    path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: t('dashboard'),
    models: ['ALL'],
    active: false

  }, {
    path: "/staffs",
    icon: "mdi mdi-account-group", name: t('RH'), badge: "sum_staff_notifs",
    // models: ['Staff'],
    has_arrow: true,
    active: false,
    subs: [{
      name: t('personnel'),
      icon: "mdi mdi-account",
      path: "/staffs/list",
      models: ['Staff']
    },{
      name: t('timeTrackings'), badge: "count_reports",
      icon: "mdi mdi-checkbox-multiple-marked-outline",
      path: "/staffs/clockingin",
      models: ['WorkReport','ClockingIn']
    },  {
      name:  t('leaves'), badge: "count_vacations",
      icon: "mdi mdi-card-account-details-outline",
      path: "/staffs/vacations",
      models: ['Vacation']
    }, 
      // ,{
      //   name: "Primes",
      //   icon: "mdi mdi-currency-eur",
      //   path: "/staffs/bonus",
      //   models: ['Staff']
      // }
    ]
  }, {
    path: "/clients", icon: "mdi mdi-account-group-outline", name: t('clients'),
    models: ['Client'],
    active: false

  }, {
    path: "/providers", icon: "mdi mdi-share-variant", name: t('providers'),
    models: ['Provider'],
    active: false

  }, {
    path: "/orders", icon: "mdi mdi-cart", name: t('supplierOrders'), badge: "count_orders",
    models: ['Order'],
    active: false

  }, {
    path: "/supplies", icon: "mdi mdi-family-tree", name: t('items'),
    models: ['Supply'],
    active: false

  }, {
    path: "/documents/list", icon: "mdi mdi-file-document-multiple", name: t('library'),
    models: ['Document'],
    active: false, 
    requireSubscriptionPro : true

  }, {
    path: "/articles/list", icon: "mdi mdi-package-variant-closed", name: t('ouvrages'),
    models: ['Article'],
    active: false

  }, {
    path: "/materials/list", icon: "mdi mdi-tools", name: t('equipments'),
    models: ['Material'],
    active: false

  }, {
    path: "/quotes/list", icon: "mdi mdi-file-document", name: t('quotes'),
    models: ['Quote'],
    active: false

  }, {
    path: "/stocks/list", icon: "mdi mdi-package-variant", name: t('warehouses'),
    models: ['Stock'],
    active: false

  }, {
    path: "/projects/list", icon: "mdi mdi-handshake", name:  t('constructionSites'),
    models: ['Project'],
    active: false

  }, {
    path: "/plannings", icon: "mdi mdi-calendar", name: t('planning'),
    has_arrow: true,
    active: false,
    subs: [{
      name: t('interventions'),
      icon: "mdi mdi-chart-gantt",
      path: "/plannings/tasks",
      models: ['Task']
    }, {
      name: t('carte'),
      icon: "mdi mdi-map-outline",
      path: "/plannings/map",
      models: ['Task','Project']
    }, {
      name: t('vehicles'),
      icon: "mdi mdi-truck",
      path: "/plannings/machines",
      models: ['Machine']
    }, {
      name: t('equipments'),
      icon: "mdi mdi-tools",
      path: "/plannings/materials",
      models: ['Material']
    }]
  }, {
    path: "/parkings/list", icon: "mdi mdi-parking", name:  t('vehicleFleet'),
    models: ['Machine'],
    active: false

  },
  // {
  //   path: "/inscriptions/list", icon: "mdi mdi-credit-card-check-outline", name: "Inscriptions",
  //   models: ['Inscription']
  // }, 
  {
    path: "/settings", icon: "mdi mdi-cog", name: t('settings'),
    // models: ['Permission', 'Activity', 'Role', 'DrivingLicense', 'Unit',
    //   'ProjectType',
    //   'ArticleType',
    //   'StockType',
    //   'QuotePhase',
    //   'RepairType',
    //   'College'
    // ],
    has_arrow: true,
    active: false,
    subs: [
      //   {
      //   name: "Permissions",
      //   icon: "mdi mdi-account-lock",
      //   path: "/settings/permissions",
      //   models: ['Permission']
      // }, 
      {
        name: t('activities'),
        icon: "mdi mdi-hard-hat",
        path: "/settings/activities",
        models: ['Activity']
      }, {
        name: t('professions'),
        icon: "mdi mdi-account-hard-hat",
        path: "/settings/roles",
        models: ['Role']
      },
      {
        name: t('collegeRattachement'),
        style: { fontSize: 10 },
        icon: "mdi mdi-school",
        path: "/settings/college",
        models: ['College']
      },
      {
        name: t('ppeClothing'),
        icon: "mdi mdi-account-cowboy-hat",
        path: "/settings/clothes",
        models: ['PPEClothingType']
      },
      //  {
      //   name: "Autorisations",
      //   icon: "mdi mdi-lock-outline",
      //   path: "/settings/authorizations",
      //   models: ['Authorization']
      // },
      {
        name: t('license'),
        icon: "mdi mdi-car",
        path: "/settings/licenses",
        models: ['DrivingLicense']
      },
      {
        name: t('indemnite'),
        icon: "mdi mdi-currency-eur",
        path: "/settings/bonus",
        models: ['TravelBonus', 'MealBonus']
      },
       {
        name: t('units'),
        icon: "mdi mdi-weight-kilogram",
        path: "/settings/units",
        models: ['Unit']
      }, {
        name: t('projectType'),
        icon: "mdi mdi-handshake",
        path: "/settings/project-types",
        models: ['ProjectType']
      }, {
        name: t('OuvrageType'),
        icon: "mdi mdi-package-variant-closed",
        path: "/settings/article-types",
        models: ['ArticleType']
      }, 
      {
        name: t('ReparationType'),
        icon: "mdi mdi-cog",
        path: "/settings/reparation-types",
        models: ['RepairType']
      }, 
      {
        name: t('equipementType'),
        icon: "mdi mdi-tools",
        path: "/settings/material-types",
        models: ['MaterialType']
      }, {
        name:  t('typeVehicle'),
        icon: "mdi mdi-train-car",
        path: "/settings/machine-types",
        models: ['PROVIDER']
      }, {
        name: t('quotePhases'),
        icon: "mdi mdi-file-document",
        path: "/settings/quote-phases",
        models: ['QuotePhase']
      }, 
      {
        name: t('WarehouseType'),
        icon: "mdi mdi-package-variant",
        path: "/settings/stock-types",
        models: ['StockType']
      }, {
        name: t('quoteModels'),
        icon: "mdi mdi-file-document-edit-outline",
        path: "/settings/quote-models",
        models: ['QuotePhase', 'Quote']
      },{
        name: t('tracabilities'),
        icon: "mdi mdi-map-marker-path",
        path: "/settings/traceability",
        models: ['Traceability', 'CableType', 'AchievementCondition']
      }]
  }]


  /*
  <li>
    <Link to="/settings/categories">
      <i className="mdi mdi-cog"></i>
      <span>Sous catégories</span>
    </Link>
  </li> 
  <li>
    <Link to="/settings/ouvrage">
      <i className="mdi mdi-cog"></i>
      <span> Ouvrage</span>
    </Link>
  </li>
  */

  // console.log("====> permisions", permissions);

  const getPermissionData = async () => {
   await dispatch(getPermissions({}, (resp) => {
    if(resp?.permissions?.length && !permissions?.length ){
      permissions = resp.permissions
    }

    }, (error) => { }))
  }
  useEffect(() => {

    // setTimeout(() => {
    getPermissionData()

    getAllLicences()

    // }, 100);

    // if (user?.userType === "STAFF") {
    //   getPermissionData()
    // }
    // if (user?.userType === "ADMIN") {
    //   getAllLicences()
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])



  // useEffect(() => {
  //   setTimeout(() => {
  //     getPermissionData()
  //     getAllLicences();

  //   }, 100);
  
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user])



  const getAllLicences = async () => {
    try {
      setLoading(true);
      const response = await new Promise((resolve, reject) => {
        dispatch(getLicences({}, (resp) => {
          resolve(resp);
        }, (err) => {
          reject(err);
        }));
      });

      setLoading(false);

      const mdls = (response?.permissions?.length)
        ? response.permissions.filter((r) => Object.keys(r).length !== 0)
        : [];

      // setLicences(mdls);

      const findPro = mdls.find((m) => (Number(m.code) === 1));
      let subscriptionPro = false;
      if (findPro) {
        subscriptionPro = true;
      } else {
        subscriptionPro = false;
      }
      await getSideBarData(mdls, subscriptionPro)
    } catch (err) {
      setLoading(false);
      console.error('Error fetching licences:', err);
    }
  };


  // useEffect(() => {
  //   // console.log('RERENDER BY TYPE', user?.userType);
  //    if(!loading) getSideBarData()


  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user,permissions])

 const getSideBarData = async (licences, subscriptionPro) => {
  //  if (!loading) {
     switch (user?.userType) {
       case "ADMIN":
         const admin_menu_data = []
         // const models = licences?.flatMap(l => l.models.map(m => (Object.keys(m)[0]))) || [];
         const models = licences?.flatMap(l => l.models.map(m => Object.keys(m)[0])) || [];

         // for (const data of all_menu_data) {
         for (let i = 0; i < all_menu_data.length; i++) {
           const data = all_menu_data[i];

           if (data.subs?.length) {
             const new_subs = []
             for (const sub of data.subs) {

               const sub_allowed = models.filter((permission) => {
               const hasAllModels = sub.models.includes("ALL");
               const hasPermission = sub.models.includes(permission);

               if (sub?.requireSubscriptionPro && !subscriptionPro) {
                 return false;

               }
               return hasAllModels || (hasPermission);
             });

               if (sub_allowed && sub_allowed?.length) new_subs.push(sub);
             }


             if (new_subs?.length) {
               admin_menu_data.push({ ...data, subs: new_subs });
             }
           }
           else {
            // const allowed = models.filter((permission) => {
            //   return (
            //     data.models?.includes("ALL") || (data.models?.includes(permission)
            //      && !!data?.requireSubscriptionPro === subscriptionPro
            //     ) || (data.models?.includes(permission)
            //     &&  subscriptionPro
            //    )
            //   )
            // })
             const allowed = models.filter((permission) => {
               const hasAllModels = data.models.includes("ALL");
               const hasPermission = data.models.includes(permission);

               if (data?.requireSubscriptionPro && !subscriptionPro) {
                 return false;

               }
               return hasAllModels || (hasPermission);
             });



             if (allowed && allowed?.length) admin_menu_data.push(data)
           }
         }
         console.log('admin_menu_data', admin_menu_data);
         setMenuData(admin_menu_data || [])

         break;
       case "SUPER_ADMIN":
         setMenuData([{
           path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: t('dashboard')
         },
         {
           path: "/subscriptions", icon: "mdi mdi-credit-card-outline", name: t('subscriptionConfiguration')
         },
         {
           path: "/subscribers", icon: "mdi mdi-card-account-details-outline", name: t('subscribedClients'), badge: "count_admins"
         },
         {
           path: "/staffs/all", icon: "mdi mdi-account-multiple-outline", name: t('users'), badge: "count_staff"
         },
         {
           path: "/user-subscriptions", icon: "mdi mdi-playlist-check", name: t('subscriptions'), badge: "count_subscription"
         }
           // , { path: "/modules", icon: "mdi mdi-package", name: {t('modules')} }
         ])
         break;


       case "STAFF":
         const new_menu_data = []
         // for (const permission of permissions) {
         //   if(permission.allowAll){

         //   }
         //   console.log(permission.model);
         // }
         let expectedRoles = ["CREATE", "READ", "UPDATE", "DELETE"];
         expectedRoles = expectedRoles?.slice().sort;


         for (const data of all_menu_data) {
           if (data.subs?.length) {
             const new_subs = []
             for (const sub of data.subs) {
               const sub_allowd = permissions.filter((permission) => {
                 return (
                   sub.models?.includes("ALL") ||
                   (
                     (permission && (permission.allowAll
                       || (permission.rules?.slice().sort().every((value, index) => value === expectedRoles[index]) && permission.rules?.length === expectedRoles?.length)
                       || permission.rules?.includes('READ')))
                     && permission.rules?.length &&
                     sub.models?.includes(permission.model)
                   )
                 )
               })

               if (sub_allowd?.length) new_subs.push(sub)
             }
             if (new_subs.length) new_menu_data.push({ ...data, subs: new_subs })
           }
           else {
             const allowed = permissions?.filter((permission) => {
               return (
                 data.models?.includes("ALL") ||
                 (
                   (permission && (permission.allowAll
                     || (permission.rules?.slice().sort().every((value, index) => value === expectedRoles[index]) && permission.rules?.length === expectedRoles?.length)
                     || permission.rules?.includes('READ')))
                   && permission.rules?.length
                   &&
                   data.models?.includes(permission.model)
                   && (!(data?.requireSubscriptionPro && !subscriptionPro))

                 )
               )
             })

             if (allowed?.length) new_menu_data.push(data)
           }
         }
         setMenuData(new_menu_data);
         break;
       case "PROVIDER":
         setMenuData(all_menu_data.filter((item) => {
           return (
             item && (
               item.models?.includes('ALL')
               // || item.path === "/providers"
               || item.path === "/orders"
             )
           )
         }))
         break;
       case "CLIENT":
         setMenuData(all_menu_data.filter((item) => {
           return (
             item && (
               item.models?.includes('ALL')
               || item.path === "/clients"
               // || item.path === "/orders"
               || item.path === "/projects/list"
               // || item.path === "/quotes/list"
             )
           )
         }))
         break;

       default: setMenuData([{
         path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: t('dashboard')
       }])
     }
// }
 }


  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}

          <SidebarContent menu_data={menu_data} />
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  const { user, permissions } = state.Login

  return {
    layout: state.Layout,
    user, permissions
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))