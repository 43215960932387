import PropTypes from 'prop-types'
import React, { useEffect, useState, } from "react"
import "../../../assets/scss/payment.scss"


import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Table
} from "reactstrap"
import PaymentSrv from "services/paymentService"

// Redux
import { useDispatch } from "react-redux"
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// import { withRouter } from "react-router-dom"

// actions

// import usePrevious from 'helpers/usePrevious'

const PaymentMdl = props => {
  // HOOKS
  // const dispatch = useDispatch();

  const { is_open, size, toggleOpen, accept, user_subscription, StripeCheckout, t } = props

  const [is_open_mdl, setOpen] = useState(false)
  const [dates, setDates] = useState([])
  // const [payment_dates, setPaymentDates] = useState([])
  let [selected_date, setSelectedDates] = useState(null)
  let [selected_price, setSelectedPrice] = useState("")

  const [success_dlg, setSuccessDLG] = useState(false)
  const [echec_dlg, setEchecDLG] = useState(false)

  useEffect(() => {
    if (typeof is_open === 'boolean') setOpen(is_open)
  }, [is_open])



  const getDaysBetweenDates = (startDate, endDate) => {
    const now = moment(startDate).clone(), dates = [];
    while (now.isSameOrBefore(endDate)) {
      dates.push({ date: now.format('YYYY-MM'), editable: false });
      now.add(1, 'months');
    }
    return dates;
  };

  const checkPaymentDate = (date) => {

    if (user_subscription?.paymentDates) {
      const findIndex = user_subscription?.paymentDates.findIndex(a =>
        String(moment(a.date).format('YYYY-MM')) === String(date?.date)
        && a.isPayed)
      if (findIndex < 0) return false
      else return true
    }
    return false
  };


  useEffect(() => {
    var dateList = getDaysBetweenDates(user_subscription.validatedAt, user_subscription.renewalDate);
    if (user_subscription?.paymentDates) {
      for (let i = 0; i < user_subscription?.paymentDates.length; i++) {
        const element = user_subscription?.paymentDates[i];
        if (element.date) {
          const findIndex = dateList.findIndex(d => String(d.date) === String(moment(element.date).format('YYYY-MM')))
          if (findIndex < 0) dateList.push({
            date: moment(element.date).format('YYYY-MM'),
            editable: false
          })
        }
      }
    }
    setDates(dateList)
  }, [])



  const payNow = async token => {
    try {
      PaymentSrv.create({
        amount: Number(user_subscription?.totalTTC || 0) * 100,
        metadata: {
          subscription: user_subscription._id
        },
        token,
        isRenewed: user_subscription?.status?.code === 4 ? true : false,
        date: selected_date || null,
        price: selected_price
      })
        .then((response) => {
          if (response && response?.data?.success) {
            // toggleOpen()
            setSuccessDLG(true)
            // const resp = {
            //   role: response.data.data.role,
            // }

            // dispatch({
            //   // type: CREATE_ROLE_SUCCESS,
            //   // payload: resp,
            // });

            // callback(resp);
          }
          else {

            // if(callbackError) callbackError({ error: response?.data.error });
            setEchecDLG(true)

          }
        })
        .catch(e => {
          setEchecDLG(true)

          // dispatch({
          //   // type: CREATE_ROLE_FAIL,
          //   payload: { error: {} }
          // });
          // if(callbackError) callbackError(e?.response?.data?.error);
        });

    } catch (error) {
      setEchecDLG(true)
      console.log(error);
    }
  };

  return (

    <Modal isOpen={is_open_mdl} size={size || "lg"} toggle={toggleOpen} >
      <ModalHeader>
        {t('paymentConfirmation')}
      </ModalHeader>



      {user_subscription.status?.code !== 4 ? <ModalBody key="direct_payment">
        <div>
          <p><span className="frm-label"> {t('unitPriceHT')} : </span>{Number(user_subscription?.total || 0)} €</p>
          <p> <span className="frm-label"> {t('unitPriceTTC')} : </span>{Number(user_subscription?.totalTTC || 0)} €</p><br></br>
          <h4> <p> <span className="frm-label"> {t('amountToPay')} : </span>{Number(user_subscription?.totalTTC || 0)} € </p></h4>
        </div>

      </ModalBody> :
        <ModalBody  key="date_payment">
          <Table className="table outline-table" striped style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th style={{ whiteSpace: "nowrap" }}>{t('date')}</th>
                <th style={{ whiteSpace: "nowrap" }}>{t('totalHT')}</th>
                <th style={{ whiteSpace: "nowrap" }}>{t('totalTTC')}</th>
                <th style={{ whiteSpace: "nowrap" }}></th>

              </tr>
            </thead>
            {dates && dates.length ? dates.map((date,i) => (
                
              <tbody>

                <tr  key={i} >
                  <td  key={"date_"+i}>
                      <input type="month"
                      value={date.date || null}
                       key={"input_"+i}
                      min={  moment(user_subscription.validatedAt).format('YYYY-MM') }
                      disabled={!date.editable}
                      placeholder="YYYY-MM"
                      className="form-control" onChange={e => {
                        try {
                          console.log('e.target.value', e.target.value);
                          const new_interlocutors = [...dates];
                          new_interlocutors[i] = { date: e.target.value, editable: true }
                          setDates(new_interlocutors)
                        } catch (error) { console.log('error', error) }
                      }}
                    />

                  
                  </td>
                  <td  key={"ttc_"+i}>{i===0? Number(user_subscription?.total || 0) :Number(user_subscription?.totalByMonth || 0) } €</td>
                  <td  key={"total_"+i}>{i===0?Number(user_subscription?.totalTTC || 0):Number(user_subscription?.totalByMonthTTC || 0) } €</td>

                  <td key={"pay_"+i}  style={{ textAlign: "center" }} >

                    {!checkPaymentDate(date) ? 
                      <span   onClick={async () => {
                        try {
                          const date = dates[i]?.date;
                          selected_date = date
                          setSelectedDates(date)
                          const price = i === 0 ? Number(user_subscription?.totalTTC || 0) * 100 : Number(user_subscription?.totalByMonthTTC || 0) * 100;
                          selected_price = price
                          setSelectedPrice(price)
                        } catch (error) { console.log('error', error) }
                      }} color="primary" key={"span_"+i}>

                        <StripeCheckout
                          stripeKey={String(process.env.REACT_APP_STRIPE_KEY)}
                          label="Payer"
                          name="Paiement abonnement"
                          billingAddress
                          key={"stripe_" + i}                      // shippingAddress
                          amount={i === 0 ? Number(user_subscription?.totalTTC || 0) * 100 : Number(user_subscription?.totalByMonthTTC || 0) * 100}
                          icon="mdi mdi-credit-card"
                          description={`Votre total est $ ${i === 0 ? Number(user_subscription?.totalTTC || 0) : Number(user_subscription?.totalByMonthTTC || 0)}`}
                          token={payNow}>
                        </StripeCheckout>
                      </span>
                   : <a style={{ color: "#15a920" }} key={"total_"+i}>{t('paid')}</a>}
                  </td>

                </tr>
              </tbody>

            )) : null}
          </Table>

          <div className="mt-2" style={{ width: "100%", textAlign: "left" }} >
                <Button onClick={() => {
                  try {                 
                    setDates([...dates,{date: moment().format('MM-YYYY'), editable:true}])
                  } catch (error) { }
                }} color="primary" outline size="sm">+ {t('addDate')}</Button>
              </div>
             

        </ModalBody>}


      <ModalFooter>
 
        <Button onClick={() => {
          toggleOpen()
        }} color="secondary" outline className="waves-effect" style={{ width: 127, lineHeight: '1.1' }}>
          {t('cancel')}
        </Button>

        {user_subscription.status?.code !== 4 ? <StripeCheckout
          stripeKey={String(process.env.REACT_APP_STRIPE_KEY)}
          label="Payer"
          name="Paiement abonnement"
          billingAddress
          // shippingAddress
          amount={Number(user_subscription?.totalTTC || 0) * 100}
          icon="mdi mdi-credit-card"
          description={`Votre total est $ ${Number(user_subscription?.totalTTC || 0)}`}
          token={payNow}

        /> : null}
      </ModalFooter>
      {success_dlg ? (
        <SweetAlert
        style={{ height: "auto" }}
          success
          title={"Paiement réussi"}
          onConfirm={() => {
            toggleOpen()
            setSuccessDLG(false)
            window.location.href="/inscriptions/list"
          }}
        >
         {t('paymentSuccessfulMessage')}
        </SweetAlert>
      ) : null}
      {echec_dlg ? (
        <SweetAlert
        style={{ height: "auto" }}
          error
          title={"Paiement échoué"}
          onConfirm={() => {
            toggleOpen()
            setEchecDLG(false)
            window.location.href="/inscriptions/list"
          }}
        >
          {t('paymentFailedMessage')}
        </SweetAlert>
      ) : null}
    </Modal>

  )
}
PaymentMdl.propTypes = {
  is_open: PropTypes.bool.isRequired,
  size: PropTypes.string,
  toggleOpen: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired
}

export default withTranslation() (PaymentMdl)

// const mapStatetoProps = ({ ProjectTypeState }) => {
//   // const { loading_add_project_type, error } = ProjectTypeState
//   return {
//     // loading_add_project_type, error
//   }
// }

// export default withRouter(
//   connect(mapStatetoProps, {
//     createProjectType
//   })(PaymentMdl)
// )
