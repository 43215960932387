// import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { registerUser } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-dark.png"
import { withTranslation } from 'react-i18next';

const Register = props => {
  // const history = useHistory();

  // HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector(({ Login }) => Login);

  const [msg_check, setMsgCheck] = useState("")
  const [loading, setLoading] = useState(false)

 const checkData = (data) => {
    try {
      if (data.password.length < 8) return props.t('passwordLengthError') // 
      if (data.confirm !== data.password) return props.t('passwordConfirmationError') // 
    } catch (error) {
      return props.t('checkData') // 
    }
    return ""
  }
  const submit = (data) => {
    const check = checkData(data); setMsgCheck(check);

    if (check === "") {
      setLoading(true)
      dispatch(registerUser({ data }, (resp) => {
        setLoading(false)
        window.location.href = "/login"
      }, (err) => {
        if(err?.message && err?.message !== "") setMsgCheck(err?.message)
        else setMsgCheck(props.t('checkData')) // 
        setLoading(false)
        console.log(err);
      }))
    }
  }

  useEffect(() => {
    if (user?._id && localStorage.getItem("authUser") !== null) {
      window.location.href = "/dashboard"
    }
  }, [user])

  return (<React.Fragment>
    <MetaTags><title>{props.t('signup')} | AppliBTP</title></MetaTags> {/* 'AppliBTP' key added as it was not found in the translation file // */}

    <div className="account-pages pt-sm-5" style={{ backgroundColor: "#f8f8fb", minHeight: 900 }} >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="" style={{ backgroundColor: "#264b9a", height: 130 }}>
                {/* bg-primary bg-soft */}
                <Row>
                  <Col xs={7}>
                    <div className="text-primary" style={{ padding: "19px 0px 19px 19px" }}>
                      <h5 className="" style={{ color: "#fff", fontSize: 21 }} >{props.t('welcomeMessage')}</h5>
                      <p style={{ color: "#fff", fontSize: 12 }}>{props.t('subscribeMessage')}</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/" className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title login-logo-wrap">
                        <img src={logo} alt="" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <AvForm className="form-horizontal" onValidSubmit={(event, values) => {
                    event?.preventDefault()
                    submit(values)
                  }}>

                    {msg_check && msg_check !== "" ? (<Alert color="danger">{msg_check}</Alert>) : null}

                    <div className="mb-3">
                      <AvField className="form-control"
                        name="companyName" label={props.t('companyName')} placeholder={props.t('companyName')}
                        value="" type="text" required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField className="form-control"
                        name="email" label={props.t('emailAddress')} placeholder="Email"
                        value="" type="email" required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="password" label={props.t('password')} placeholder={props.t('password')}
                        value="" type="password" required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="confirm" label={props.t('confirmPassword')} placeholder="{t('confirmation')}"
                        value="" type="password" required
                      />
                    </div>

                    <div className="mt-3 d-grid">
                      <button disabled={loading} type="submit" className="btn btn-primary btn-block waves-effect waves-light">
                        {/* {props.t('signup')} */}
                        {props.t('registrationRequest')}
                      </button>
                    </div>
                    
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <Link to="/mentions-legales" style={{ textTransform: "uppercase", textAlign: "center", display: "block", marginTop: 10 }}>{props.t('legalNotice')}</Link>
          </Col>
      
        </Row>
      </Container>
    </div>
  </React.Fragment>)
}
export default withTranslation()(Register)
