// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const PermissionSrv = {

  // =============== GET USERS PERMISSIONS
  getListUsersPermissions: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.users}/permissions/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ALL MODELS
  getModels: (payload) => {
    // console.log('payload',`${endpoints.root}/licences/models/${payload.user_id}`);
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.root}/licences/models/${payload.user_id}`)
        .then(resp => {
          // console.log('resp==>',resp);
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  // =============== GET ALL LICENCES
  getLicences: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.root}/permissions/admin`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  


  // =============== GET MODEL PERMISSIONS
  getModelPermissions: (payload) => {
    return new Promise((resolve, reject) => {      
      // const url = payload.by_role ?
      //   `${endpoints.permissions}?role=${payload.role_id}&model=${payload.model}`
      //   : `${endpoints.permissions}/user/${payload.user_id}/${payload.model}`
      const url = `${endpoints.permissions}/user/${payload.user_id}/${payload.model}`;
      return axiosApi.get(url)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.permissions}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  getPermissionSupplies: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.permissions}/${payload.permission_id}/supplies?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.permissions}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.permissions, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== CREATE LIST PERMISSIONS
  createList: (payload) => {
    const promises = []
    for (const mdl of payload.data) {
      promises.push(new Promise((resolve, reject) => {
        return axiosApi.post(endpoints.permissions, mdl)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      }))
    }

    // return new Promise.all(promises)
    return new Promise((resolve, reject) => {
      Promise.all(promises).then((values) => {
        resolve(values);
      }).catch(e => reject(e))
    })
  },
  deleteStaffLicence: (payload) => {
    return new Promise((resolve, reject) => {
        return axiosApi.delete(`${endpoints.licences}/${payload?._id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
  },
  createStaffLicence: (payload) => {
    return new Promise((resolve, reject) => {
        return axiosApi.post(endpoints.licences, payload.data)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
  },
 getStaffLicence: (payload) => {
  let filters = ""
  if (payload.filters) {
    for (const key in payload.filters) {
      if (Object.hasOwnProperty.call(payload.filters, key)) {
        if (payload.filters[key] && payload.filters[key] !== "")
          filters += `&${key}=${payload.filters[key]}`
      }
    }
  }

    return new Promise((resolve, reject) => {
        return axiosApi.get(`${endpoints.licences}?${filters}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
  },
  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.permissions}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.permissions}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  }
}

export default PermissionSrv