import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/avatar.jpg"
import { endpoints } from "helpers/apiConfigs"

const ProfileMenu = props => {
  const { t } = props
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { user } = useSelector(({ Login }) => Login);

  // const [username, setusername] = useState("")

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.displayName)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.username)
  //     }
  //   }
  // }, [props.success])

  const renderUserPic = () => {
    try {
      if (user?.profile?.picture?.path) return `${endpoints.root}/${user.profile.picture.path}`

      return user1
    } catch (error) {
      return user1
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={renderUserPic()}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{`${user?.profile?.firstName || ""} ${user?.profile?.lastName || ""}`}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {user.userType !== "SUPER_ADMIN" && user.userType !== "CLIENT" && (
            <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {t('profil')}
            </DropdownItem>
          )}

          {user.userType === "ADMIN" && (
            <DropdownItem tag="a" href="/inscriptions">
              <i className="mdi mdi-credit-card-check-outline font-size-16 align-middle me-1" />
              {t('subscriptions')}
            </DropdownItem>
          )}
          
          {user.userType !== "SUPER_ADMIN" && user.userType !== "CLIENT" && (
            <DropdownItem tag="a" href="/company">
              <i className="bx bx-wrench font-size-16 align-middle me-1" />
              {t('settings')}
            </DropdownItem>
          )}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t('logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
