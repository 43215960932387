import PropTypes from 'prop-types'
import React, { useState } from "react"

import {
  Table
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import { isFunction } from 'helpers/utils'
// actions
// import {
//   changeCrumb
// } from "../../store/actions"
import ReactTooltip from 'react-tooltip'
import { withTranslation } from 'react-i18next'


const ListingTable = props => {
  const { headers, children, changeSortBy, table_style, tbody_style, container_style, t } = props
  const [sort_asc, setSortAsc] = useState(true)
  const [sort_by, setSortBy] = useState("")

  return (
    <React.Fragment>
      <div className="table-responsive" style={container_style ? container_style : {}} >
        <Table className="table mb-0 appli-table" style={table_style || {}}>
          <thead>

            <tr>
              {headers.map((heading, i) => <th key={"heading_" + i}

                onClick={() => {
                  if (heading.sorting && isFunction(changeSortBy)) changeSortBy(
                    { name: heading.sort_by, value: (sort_asc ? "" : "-") + heading.sort_by }
                  )

                  setSortAsc(!sort_asc)
                  setSortBy(heading.sort_by || "")
                }}


                className={(heading.class || "") + " " + classNames({
                  "heading_sort": (heading.sorting && sort_by === heading.sort_by),
                })}

                style={heading.style || {}}
              >
                <span style={heading.sorting ? { cursor: "pointer" } : {}} >{heading.name}</span>

                {(heading.sorting && sort_by === heading.sort_by) && <span className="tbl-sort-icon" >
                  <i className={sort_asc ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}></i>
                </span>}
                {heading.detailsSubscription && heading?.name === 'Statut' ?

                  <>

                    <span className="tbl-sort-icon" style={{ paddingLeft: '0.25rem' }}
                      onMouseEnter={() => ReactTooltip.rebuild()}
                      data-tip={`
        <a style="font-size:10px;text-transform: capitalize;">${t('created')} : ${t('subscriptionCreated')} </a>  </br>
        <a style="font-size:10px;text-transform: capitalize;">${t('validated')} : ${t('subscriptionValidated')} </a> </br>
        <a style="font-size:10px;text-transform: capitalize;">${t('expired')} : ${t('subscriptionExpired')} </a> </br>
        <a style="font-size:10px;text-transform: capitalize;">${t('canceled')} : ${t('subscriptionCanceled')} </a> </br>
        <a style="font-size:10px;text-transform: capitalize;">${t('renewed')} : ${t('subscriptionRenewed')} </a> </br>
        <a style="font-size:8px;text-transform: lowercase;">   - ${t('contactAdminToDisableAutoRenewal')} </a> </br>
        <a style="font-size:10px;text-transform: capitalize;">${t('paid')} : ${t('subscriptionPaid')} </a>
      `}
                      data-for={'112233'}
                    >

                      <ReactTooltip
                        id={'112233'}
                        // backgroundColor="#fff" textColor="#333" borderColor="#444"
                        arrowColor="#264b9a" type="info" multiline html={true}
                        backgroundColor="white" textColor="#6d7995" border={true} borderColor="#6d7995"
                        place={"bottom"}
                      />
                      <i className="mdi mdi-information-outline"></i>
                    </span> </> : null}

              </th>)}
            </tr>
          </thead>
          <tbody style={tbody_style || {}}>
            {children}
          </tbody>
        </Table>
      </div>

    </React.Fragment >
  )
}
ListingTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    width: PropTypes.number,
    class: PropTypes.string,
  })).isRequired,

  changeSortBy: PropTypes.func,
  table_style: PropTypes.object,
  tbody_style: PropTypes.object,
  container_style: PropTypes.object
}

const mapStatetoProps = state => {
  // const {  } = state.Profile
  return {}
}

export default withRouter(
  connect(mapStatetoProps, {

  })(withTranslation()(ListingTable))
)
