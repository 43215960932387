import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import PermissionsContext from "components/PermissionsContext";
import i18n from "i18n";

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        {/* Wrap your entire application with the I18nextProvider */}
          <PermissionsContext>
          <I18nextProvider i18n={i18n}>

            <App />
            </I18nextProvider>

          </PermissionsContext>
        
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
