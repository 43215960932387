import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Button, Spinner, Label, Input, UncontrolledAlert } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"
import UserSrv from 'services/UserSrv';
// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { withTranslation } from 'react-i18next';

const ForgetPasswordPage = props => {
  const { t } = props
  const [password, setPassword] = useState(null)
  const [confirm_pass, setConfirmPassword] = useState(null)
  const [token, setToken] = useState(null)
  const [email, setEmail] = useState(null)
  const [passed, setPassed] = useState(false)
  const [failed, setFailed] = useState(false)

  const [msg, setMessage] = useState("")

  function handleValidSubmit(event, values) {
    setEmail(values.email)
    UserSrv.forget({ data: { email: values.email } })
      .then((response, error) => {
        if (response) {
          if (response.status === 200) {
            setFailed(false)
            setPassed(true)
            setMessage(response.data)
          }
        } else {
          console.log('reee', response);
          console.log('error', error);
          setFailed(true)
          setPassed(false)
          setMessage(response.data)
        }
  })
  .catch(e => {
  setFailed(true)
  setPassed(false)
  setMessage(t('checkData'))
  });
  }

  const reset = () => {
    UserSrv.reset({ data: { token, password, email } })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setFailed(false)
            setPassed(true)
            setMessage(response.data)
            setTimeout(() => {
              props?.history.push('/login')
            }, 500);
          }
        } else {
          setFailed(true)
          setPassed(false)
          setMessage(response.data)
        }
    })
    .catch(e => {
      setFailed(true)
      setPassed(false)
      setMessage(t('checkData'))
    });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t('forgetPassword')}</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {failed && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
                <i className="mdi mdi-block-helper me-2"></i>{msg}
              </UncontrolledAlert>}
              {passed && <UncontrolledAlert color="success" className="hide-alert-close" role="alert" >
                <i className="mdi mdi-checkbox-marked-circle me-2"></i>{msg}
              </UncontrolledAlert>}
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t('welcomeBack')}</h5>
                        <p>{props.t('loginToContinue')}</p> {/* 'loginToContinue' key added as it was not found in the translation file */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={props.t('email')}
                          className="form-control"
                          placeholder=" Email"
                          type="email"
                          validate={{
                            email: {value : true , errorMessage :props.t('invalidEmail')},
                            required: { value: true, errorMessage: props.t('requiredEmail') },
                          }}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {props.t('reset')}
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        {passed ?
                          <Row>
                            <Col md="12" className="mt-3">
                              <Label>{props.t('codeSent')}</Label> 
                              <Input type="text" className="form-control" value={token} onChange={(e) => setToken(e.target.value)} />
                            </Col>
                            <Col md="6" className="mt-3">
                              <Label>{props.t('newPassword')}</Label> 
                              <Input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Col>
                            <Col md="6" className="mt-3">
                              <Label>{props.t('confirmation')}</Label>
                              <Input type="password" className="form-control" value={confirm_pass} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </Col>
                            <Col md="6" style={{ marginTop: 42 }}>
                              <Button disabled={(confirm_pass !== password || password === "")} onClick={() => {
                                reset()
                              }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
                                {props.t('change')}
                              </Button>
                            </Col>
                          </Row>
                          : null
                        }
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t('returnToPage')}{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    {props.t('login')}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(withTranslation() (ForgetPasswordPage))
)
