import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from 'utils/languages/en.json';
import french from 'utils/languages/fr.json';
import arabic from 'utils/languages/ar.json';
import chinese from 'utils/languages/ch.json';
import detector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: english,
  },
  fr: {
    translation: french,
  },
  ar: {
    translation: arabic,
  },
  ch: {
    translation: chinese,
  },
};

// Set 'fr' as the default language in localStorage if not already set
const language = localStorage.getItem('i18nLng');
if (!language) {
  localStorage.setItem('i18nLng', 'fr');
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('i18nLng') || 'fr',
    fallbackLng: 'fr', // use 'fr' if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: true,
    backend: {
      loadPath: '/utils/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
