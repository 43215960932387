import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { NavLink } from "react-router-dom"

import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"

//ACTIONS
import { getCounts } from "store/actions"

// COMPONENTS
import DashCardCmp from "../DashCardCmp"
import ListSubscribersCmp from "components/Modules/Subscribers/ListSubscribersCmp"
import ListSubscriptionsGridCmp from "components/Modules/Subscription/ListSubscriptionsGridCmp"
import ListInscriptionsGridCmp from "components/Modules/Inscription/ListInscriptionsGridCmp"
import UserSubscriptionsPage from "pages/UserSubscriptions/list-user-subscription"
import { withTranslation } from "react-i18next"

const SuperAdminDashCmp = props => {
  // HOOKS
  const { t } = props
  const dispatch = useDispatch()

  const { counts, loadingCounts } = useSelector(state => state.CountState)
  useEffect(() => {
    dispatch(
      getCounts(
        {
          countBody: [
            {
              model: "User",
            },
            {
              model: "User",
              filter: {
                userType: "ADMIN",
              },
            },
            {
              model: "User",
              filter: {
                userType: "CLIENT",
              },
            },
            {
              model: "User",
              filter: {
                userType: "PROVIDER",
              },
            },
            {
              model: "User",
              filter: {
                userType: "STAFF",
              },
            },
            {
              model: "Subscription",
            },
            {
              model: "UserSubscription",
            },
            {
              model: "STAFF",
              
            },
            {
              model: "UserSubscription",
              filter: {
                'status.code': 0,
              },
            },
          ],
        },
        () => null,
        () => null
      )
    )
  }, [])

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col md="6">
          <DashCardCmp
            name={t('users')}
            icon="mdi mdi-account-group-outline"
            value={counts[4]?.count + " "+t('users')}
            // addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="6">
          <DashCardCmp
            name={t('subscribedClients')}
            icon="mdi mdi-briefcase"
            value={counts[1]?.count + " "+t('subscribedClients')}
            // addition="+3 ce mois"
            loading={loadingCounts}
          />
        </Col>
       
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <DashCardCmp
            name={t('subscriptions')+" "+counts[6]?.count}
            icon="mdi mdi-credit-card-multiple"
            value={<><p style={{ color: counts[8]?.count > 0 ? "#ff0000" : "" }}>
              {counts[8]?.count === 1 ? counts[8]?.count + " "+t('newSubscription') : counts[8]?.count + " "+t('newSubscriptions')}
            </p>
           </>
          }
          
            // addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        {/* <Col md="3">
          <DashCardCmp
            name="Abonnés"
            icon="mdi mdi-account-multiple"
            value={counts[6]?.count + " Abonnés"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col> */}
         <Col md="3">
          <DashCardCmp
            name={t('clients')}
            icon="mdi mdi-account-multiple"
            value={counts[2]?.count + " "+t('clients')}
            // addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="3">
          <DashCardCmp
            name={t('providers')}
            icon="mdi mdi-account-multiple"
            value={counts[3]?.count+ " "+t('providers')}
            // addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        {/* <Col md="3">
          <DashCardCmp
            name="Personnel"
            icon="mdi mdi-account-multiple"
            value={counts[4]?.count + " Personnel"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col> */}
      </Row>

      
      {/* <Col md="12">
      <h4>Abonnés</h4>
      <ListSubscribersCmp />
      </Col> */}

      <Col md="12">
        <h4>{t('subscriptions')}</h4>
        <hr />
        <UserSubscriptionsPage />
      </Col>
    </React.Fragment>
  )
}
SuperAdminDashCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_modules: PropTypes.array,
}

export default withTranslation() (SuperAdminDashCmp)
