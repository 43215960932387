// import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect } from "react"
import {
  Container,
  // Button,
} from "reactstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"

// actions
import { changeCrumb } from "../../store/actions"
import EditStaffCmp from 'components/Modules/Staff/EditStaffCmp';
import EditClientCmp from 'components/Modules/Clients/EditClientCmp';
import EditProviderCmp from 'components/Modules/Providers/EditProviderCmp';
import EditProfileCmp from 'components/Modules/Users/EditProfileCmp';
import { withTranslation } from 'react-i18next';
// import EditStaffPage from 'pages/Staffs/edit-staff.page';

const UserProfile = props => {
  const { user } = useSelector(({ Login }) => Login);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeCrumb("Profil"), (resp) => { }, (error) => { })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderEditProfile = () => {
    try {
      switch (user?.userType) {
        case "STAFF": return (<EditStaffCmp staff_id={user?._id} is_profile={true} />)
        case "CLIENT": return (<EditClientCmp client_id={user?._id} is_profile={true} />)
        case "PROVIDER": return (<EditProviderCmp provider_id={user?._id} is_profile={true} />)
        case "SUPER_ADMIN":
        case "ADMIN": return (<EditProfileCmp user_id={user?._id}/>)

        default: return null
      }
    } catch (error) {
      return null
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t('profil')}</title>
        </MetaTags>
        <Container fluid>
          {renderEditProfile()}
        </Container>
      </div>
    </React.Fragment>
  )
}

// UserProfile.propTypes = {
// }

export default withTranslation()(UserProfile)
