import PermissionSrv from "services/PermissionSrv";

import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,

  GET_ONE_PERMISSION,
  GET_ONE_PERMISSION_SUCCESS,
  GET_ONE_PERMISSION_FAIL,

  CREATE_PERMISSION,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAIL,

  PATCH_PERMISSION,
  PATCH_PERMISSION_SUCCESS,
  PATCH_PERMISSION_FAIL,

  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,

  GET_PERMISSION_SUPPLIES,
  GET_PERMISSION_SUPPLIES_SUCCESS,
  GET_PERMISSION_SUPPLIES_FAIL,

  GET_USERS_PERMISSIONS,
  GET_USERS_PERMISSIONS_SUCCESS,
  GET_USERS_PERMISSIONS_FAIL,

  // GET_USER_PERMISSIONS,
  // GET_USER_PERMISSIONS_SUCCESS,
  // GET_USER_PERMISSIONS_FAIL,

  GET_MODELS,
  GET_MODELS_SUCCESS,
  GET_MODELS_FAIL,

  GET_MODEL_PERMISSIONS,
  GET_MODEL_PERMISSIONS_SUCCESS,
  GET_MODEL_PERMISSIONS_FAIL,

  CREATE_LIST_PERMISSIONS,
  CREATE_LIST_PERMISSIONS_SUCCESS,
  CREATE_LIST_PERMISSIONS_FAIL,
} from "./actionTypes"


// =============== GET USERS PERMISSIONS
export const getListUsersPermissions = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_USERS_PERMISSIONS,
    });

    PermissionSrv.getListUsersPermissions(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            users: response.data.data.users,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_USERS_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_USERS_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// GET MODELS
export const getModels = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MODELS,
    });

    PermissionSrv.getModels(payload)
      .then((response) => {
        if (response && response?.data &&  response?.data?.data) {
          const resp = {
            models: response.data.data.licences || {},
          }

          dispatch({
            type: GET_MODELS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        // else callbackError({ error: response?.error });
      })
      .catch(e => {
        dispatch({
          type: GET_MODELS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// GET MODELS
export const getLicences = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MODELS,
    });

    PermissionSrv.getLicences(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            permissions: response.data.permissions || {},
          }
          dispatch({
            type: GET_MODELS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        // else callbackError({ error: response?.error });
      })
      .catch(e => {
        dispatch({
          type: GET_MODELS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// GET USER PERMISSIONS BY MODEL
export const getUserModelPermissions = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MODEL_PERMISSIONS,
    });

    PermissionSrv.getModelPermissions(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permissions: response.data.data.accessRights,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_MODEL_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_MODEL_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== GET LIST
export const getListPermissions = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PERMISSIONS,
    });

    PermissionSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permissions: response.data.data.accessRights || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createPermission = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_PERMISSION,
    });

    PermissionSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permission: response.data.data.permission,
          }

          dispatch({
            type: CREATE_PERMISSION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_PERMISSION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

export const createListPermissions = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_LIST_PERMISSIONS,
    });

    PermissionSrv.createList(payload)
      .then((response) => {
        if (response){ //&& response?.data?.success) {
          const resp = {
            permissions: response //.data.data.permission,
          }

          dispatch({
            type: CREATE_LIST_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        // else callbackError({ error: response });
        else{
          if(callbackError) callbackError({ error: response });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_LIST_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        callbackError(e);
        if(callbackError) callbackError(e?.response);
      });
  };
};
export const createStaffLicence = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_LIST_PERMISSIONS,
    });

    PermissionSrv.createStaffLicence(payload)
      .then((response) => {
        if (response){ //&& response?.data?.success) {
          const resp = {
            licence: response //.data.data.permission,
          }

          dispatch({
            type: CREATE_LIST_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        // else callbackError({ error: response });
        else{
          if(callbackError) callbackError({ error: response });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_LIST_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        callbackError(e);
        if(callbackError) callbackError(e?.response);
      });
  };
};

export const deleteStaffLicence = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_PERMISSION,
    });

    PermissionSrv.deleteStaffLicence(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_PERMISSION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_PERMISSION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
export const getStaffLicence = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PERMISSIONS,
    });

    PermissionSrv.getStaffLicence(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            licences: response.data.data.licences || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== GET ONE
export const getOnePermission = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_PERMISSION,
    });

    PermissionSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permission: response.data.data.permission
          }

          dispatch({
            type: GET_ONE_PERMISSION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_PERMISSION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== GET PERMISSION SUPPLIES
export const getPermissionSupplies = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PERMISSION_SUPPLIES,
    });

    PermissionSrv.getPermissionSupplies(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permission_suplies: response.data.data.permissionSupplies
          }

          dispatch({
            type: GET_PERMISSION_SUPPLIES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PERMISSION_SUPPLIES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editPermission = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_PERMISSION,
    });

    PermissionSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permission: response.data.data.permission,
          }

          dispatch({
            type: PATCH_PERMISSION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_PERMISSION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deletePermission = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_PERMISSION,
    });

    PermissionSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_PERMISSION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_PERMISSION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};