import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import {
  Alert,
  Container
} from "reactstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"

// actions
import {
  changeCrumb
} from "../../store/actions"

import { MetaTags } from 'react-meta-tags'
// import CardUser from 'components/Common/CardUser/CardUser'
import AdminDashCmp from 'components/Dashboard/AdminDash/AdminDashCmp'
import SuperAdminDashCmp from 'components/Dashboard/AdminDash/SuperAdminDashCmp'
import StaffDashCmp from 'components/Dashboard/AdminDash/StaffDashCmp'
import ClientDashCmp from 'components/Dashboard/AdminDash/ClientDashCmp'

import SweetAlert from 'react-bootstrap-sweetalert'
import { withTranslation } from 'react-i18next'

const Dashboard = props => {
  const { t } = props;
  const dispatch = useDispatch()
  const { user } = useSelector(({ Login }) => Login)
  const [subscription, setSubscription] = useState(null)
  useEffect(() => {
    setSubscription(JSON.parse(localStorage.getItem("authUser"))?.subscription || null)
    dispatch(changeCrumb("Tableau de bord"), resp => { 
     
      
    }, error => { })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags><title>AppliBTP</title></MetaTags>

        <Container fluid>
        {(user?.userType !== "ADMIN" && user?.userType !== "SUPER_ADMIN" && user?.userType !== "CLIENT" ) ?  <StaffDashCmp /> : null}
        {(user?.userType === "CLIENT") ?  <ClientDashCmp /> : null}

        {user?.userType === "ADMIN" ? <AdminDashCmp /> : null}
        {user?.userType === "SUPER_ADMIN" ? <SuperAdminDashCmp /> : null}
          {/* <CardUser user={{ name: "Alex Dario", email: "Alex.dario@lwa.fr", address: "7 Rue d'Upsal, Strasbourg, France", phone: "+33 06 72 02 57 21" }} /> */}

        </Container>
      </div>
      {!subscription && user?.userType !== "SUPER_ADMIN"? (
        <SweetAlert
        style={{ height: "auto" }}
          error
          title={t('subscriptionPrompt')}
          onConfirm={() => {
            // setSuccessDLG(false)
            setSubscription(true)
          }}
        >
          {t('contactSupport')}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  changeCrumb: PropTypes.func,
}

export default withTranslation() (Dashboard)